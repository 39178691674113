/* Large desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
/* Landscape phones and down */
#navbar {
  line-height: initial;
}
header.navbar {
  background: #ccdcdc;
}
header.navbar .navbar-inner {
  background: transparent;
  min-height: auto;
  text-align: center;
  padding: 0;
}
header.navbar .navbar-inner .view-nav-label {
  display: none;
  width: auto;
  text-align: center;
  padding: 5px;
  margin: 0 auto 3px;
  background: #FFFFFF;
}
@media (max-width: 979px) {
  header.navbar .navbar-inner .view-nav-label {
    display: inline-block;
  }
}
header.navbar .navbar-inner .view-nav {
  display: none;
}
@media (max-width: 979px) {
  header.navbar .navbar-inner .view-nav:checked + .main-navigation nav .nav {
    height: auto;
    max-height: 80vh;
    overflow: auto;
  }
}
header.navbar .navbar-inner .main-navigation nav .nav {
  margin: 0;
}
@media (max-width: 979px) {
  header.navbar .navbar-inner .main-navigation nav .nav {
    height: 0;
    overflow: hidden;
  }
}
header.navbar .navbar-inner .main-navigation nav .nav li {
  padding-bottom: 3px;
  position: relative;
}
@media (max-width: 979px) {
  header.navbar .navbar-inner .main-navigation nav .nav li a {
    font-size: 80%;
  }
}
@media (max-width: 979px) and (orientation: portrait) {
  header.navbar .navbar-inner .main-navigation nav .nav li {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  header.navbar .navbar-inner .main-navigation nav .nav li {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
  }
}
header.navbar .navbar-inner .main-navigation nav .nav li:after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
  content: "";
  background: transparent;
  z-index: 1;
}
@media (max-width: 979px) and (orientation: portrait) {
  header.navbar .navbar-inner .main-navigation nav .nav li .dropdown-menu {
    position: relative;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  header.navbar .navbar-inner .main-navigation nav .nav li .dropdown-menu li {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  header.navbar .navbar-inner .main-navigation nav .nav li .dropdown-menu li a {
    padding: 7px 0;
  }
}
@media (max-width: 767px) {
  header.navbar .navbar-inner .main-navigation nav .nav li .dropdown-menu {
    position: relative;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  header.navbar .navbar-inner .main-navigation nav .nav li .dropdown-menu li {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  header.navbar .navbar-inner .main-navigation nav .nav li .dropdown-menu li a {
    padding: 7px 0;
  }
}
header.navbar .navbar-inner .main-navigation nav .nav li.active,
header.navbar .navbar-inner .main-navigation nav .nav li.active-trail {
  background: none;
}
header.navbar .navbar-inner .main-navigation nav .nav li.active:after,
header.navbar .navbar-inner .main-navigation nav .nav li.active-trail:after {
  background: #FFFFFF;
}
header.navbar .navbar-inner .main-navigation nav .nav li.active span.nolink,
header.navbar .navbar-inner .main-navigation nav .nav li.active-trail span.nolink,
header.navbar .navbar-inner .main-navigation nav .nav li.active a,
header.navbar .navbar-inner .main-navigation nav .nav li.active-trail a {
  background: none;
  box-shadow: none;
}
header.navbar .navbar-inner .main-navigation nav .nav li.active span.nolink.active,
header.navbar .navbar-inner .main-navigation nav .nav li.active-trail span.nolink.active,
header.navbar .navbar-inner .main-navigation nav .nav li.active a.active,
header.navbar .navbar-inner .main-navigation nav .nav li.active-trail a.active,
header.navbar .navbar-inner .main-navigation nav .nav li.active span.nolink:hover,
header.navbar .navbar-inner .main-navigation nav .nav li.active-trail span.nolink:hover,
header.navbar .navbar-inner .main-navigation nav .nav li.active a:hover,
header.navbar .navbar-inner .main-navigation nav .nav li.active-trail a:hover,
header.navbar .navbar-inner .main-navigation nav .nav li.active span.nolink:active,
header.navbar .navbar-inner .main-navigation nav .nav li.active-trail span.nolink:active,
header.navbar .navbar-inner .main-navigation nav .nav li.active a:active,
header.navbar .navbar-inner .main-navigation nav .nav li.active-trail a:active {
  background: none;
  box-shadow: none;
}
@media (min-width: 480px) {
  header.navbar .navbar-inner .main-navigation nav .nav li.active span.nolink,
  header.navbar .navbar-inner .main-navigation nav .nav li.active-trail span.nolink,
  header.navbar .navbar-inner .main-navigation nav .nav li.active a,
  header.navbar .navbar-inner .main-navigation nav .nav li.active-trail a {
    background: none;
    box-shadow: none;
  }
  header.navbar .navbar-inner .main-navigation nav .nav li.active span.nolink.active,
  header.navbar .navbar-inner .main-navigation nav .nav li.active-trail span.nolink.active,
  header.navbar .navbar-inner .main-navigation nav .nav li.active a.active,
  header.navbar .navbar-inner .main-navigation nav .nav li.active-trail a.active,
  header.navbar .navbar-inner .main-navigation nav .nav li.active span.nolink:hover,
  header.navbar .navbar-inner .main-navigation nav .nav li.active-trail span.nolink:hover,
  header.navbar .navbar-inner .main-navigation nav .nav li.active a:hover,
  header.navbar .navbar-inner .main-navigation nav .nav li.active-trail a:hover,
  header.navbar .navbar-inner .main-navigation nav .nav li.active span.nolink:active,
  header.navbar .navbar-inner .main-navigation nav .nav li.active-trail span.nolink:active,
  header.navbar .navbar-inner .main-navigation nav .nav li.active a:active,
  header.navbar .navbar-inner .main-navigation nav .nav li.active-trail a:active {
    background: none;
    box-shadow: none;
  }
}
